import React, { FC } from 'react';
import { Chip } from '@mui/material';

import { DealStatus, OpportunityStatus } from '@constants/common';
import { translateDynamic } from '@utils/locale';

import { StatusChipPropsType } from './types';
import { colors } from '@constants/colors';

const StatusChip: FC<StatusChipPropsType> = ({ status, isOpportunity }) => {
  const getColor = (dealStatus: DealStatus | OpportunityStatus) => {
    switch (dealStatus) {
      case DealStatus.DRAFT:
        return colors.gray01;
      case DealStatus.INFINYTE_APPROVED:
        return colors.successGreen;
      case DealStatus.IN_REVIEW:
        return colors.violet;
      case DealStatus.ACTIVE:
        return colors.orange;
      case DealStatus.EXPIRED:
        return colors.orange;
      case DealStatus.CLOSED:
        return colors.primaryBlue;
      default:
        return colors.gray;
    }
  };

  const getVariant = (statusType: DealStatus | OpportunityStatus) => {
    if (statusType === DealStatus.DRAFT || statusType === DealStatus.ACTIVE)
      return 'filled';
    return 'outlined';
  };

  const getTextColor = (statusType: DealStatus | OpportunityStatus) => {
    if (statusType === DealStatus.ACTIVE) {
      return colors.white;
    } else if (statusType === DealStatus.DRAFT) {
      return colors.black;
    }

    return getColor(statusType);
  };

  const getLabel = (statusType: string) => {
    if (isOpportunity)
      return translateDynamic(`opportunityStatus.${statusType}`);
    return translateDynamic(`dealStatus.${statusType}`);
  };

  return (
    <Chip
      label={getLabel(status)}
      size="medium"
      variant={getVariant(status)}
      style={{
        color: getTextColor(status),
        borderColor: getColor(status),
        width: 145,
        backgroundColor:
          getVariant(status) == 'filled' ? getColor(status) : colors.white
      }}
    />
  );
};

export default StatusChip;
