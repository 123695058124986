import { translate } from '@utils/locale';
import { colors } from './colors';

/* eslint-disable no-useless-escape */
export const RegularExpressions = {
  nonNumberRegExp: new RegExp(/[^0-9]/g),
  emailRegex: new RegExp(/^[\w-\.\+]+@([\w-]+\.)+[\w-]{2,}$/),
  optionalEmailRegex: new RegExp(/^([\w-\.\+]+@([\w-]+\.)+[\w-]{2,})?$/),
  phoneNoRegex: new RegExp(/^\+[0-9]+$/),
  positiveNumberRegex: new RegExp(/^[+]?((\d+(\.\d*)?)|(\.\d+))$/),
  onlyNumber: new RegExp(/^[0-9]+$/),
  onlyAlphabetsOrSpace: new RegExp(/[A-Za-z\s]/),
  nameRegex: new RegExp(/^[a-zA-Z][a-zA-Z\s\.]+[a-zA-Z\s]$/),
  noSpecialCharactersExceptHyphen: new RegExp(/^[a-zA-Z0-9\s\\-]*$/),
  nonZeroNumberRegex: new RegExp(/^(?!0*$).*$/),
  // eslint-disable-next-line max-len
  optionalLinkedInUrlRegex: new RegExp(
    /^((https?:\/\/)?(www\.)?(linkedin\.com\/(in|pub)\/[a-zA-Z0-9-_]+|in\.linkedin\.com\/[a-zA-Z0-9-_]+)(\/)?)?$/
  ),
  decimalNumberRegex: new RegExp(/^(?:\d*\.\d+|\d+|)\s*$/),
  percentageRegex: new RegExp(/^(100(\.0{1,2})?|\d{0,2}(\.\d{1,2})?)$/),
  pancardNumberRegex: new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]$/),
  aadharNumberRegex: new RegExp(/^\d{12}$/),
  mobileRegex: new RegExp(/^(?:\d{10})?$/),
  imageFileRegex: new RegExp(/\.(?:png|jpe?g)$/i),
  isinRegex: new RegExp(/^\s?[a-zA-Z0-9]*$/),
  hexColorRegex: new RegExp(/^#([A-Fa-f0-9]{8}|[A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/)
};

export const ApiTags = {
  deal: 'deal',
  dealList: 'dealList',
  syndicate: 'syndicate',
  syndicateList: 'syndicateList',
  dealInvite: 'dealInvite',
  syndicateInvite: 'syndicateInvite',
  getUserDocuments: 'getUserDocuments',
  getDealDocuments: 'getDealDocuments',
  getNomineeDetails: 'getNomineeDetails',
  getUserDetails: 'getUserDetails',
  getUserList: 'getUserList',
  getKycDetails: 'getKycDetails',
  accessCodeList: 'accessCodeList',
  recordInvestment: 'recordInvestment',
  teamMember: 'teamMember',
  rounds: 'rounds',
  sector: 'sector',
  investor: 'investor',
  getCompany: 'getCompany',
  companyList: 'companyList',
  companyConfiguration: 'companyConfiguration',
  opportunityList: 'opportunityList',
  opportunity: 'opportunity',
  earlyAccess: 'earlyAccess',
  wishlist: 'wishlist',
  analyticsUserTable: 'analyticsUserTable',
  dropCommit: 'dropCommit',
  activateCommit: 'activateCommit',
  dealAnalyticsSummary: 'dealAnalyticsSummary',
  userVerification: 'userVerification',
  userScreening: 'userScreening',
  getUserAifConfiguration: 'getUserAifConfiguration'
};

export const DEFAULT_DATE_FORMAT = 'dd/MM/yyyy';
export const DEFAULT_DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm:ss';

export const SUPPORTED_FILE_FORMATS = {
  'image/png': ['.png'],
  'image/jpg': ['.jpg', '.jpeg'],
  'image/gif': ['.gif'],
  'application/pdf': ['.pdf']
};

export const DIGITAL_MEDIA_FORMATS = {
  'image/png': ['.png'],
  'image/jpg': ['.jpg', '.jpeg'],
  'image/gif': ['.gif']
};

export const IMAGE_FORMATS = {
  'image/png': ['.png'],
  'image/jpg': ['.jpg', '.jpeg']
};

export const DOCUMENT_FORMAT = {
  'application/pdf': ['.pdf']
};

export const DIGITAL_DOCUMENT_FORMAT = {
  ...IMAGE_FORMATS,
  ...DOCUMENT_FORMAT
};

export const CROP_UNSUPPORTED_FORMATS = {
  gif: 'image/gif',
  pdf: 'application/pdf'
};

export const FILE_UPLOAD_STATUS = {
  uploading: 'loading',
  failed: 'failed',
  success: 'success'
};

export const API_RESPONSE_STATUS = {
  OK: 'ok',
  NOT_OK: 'nok'
};

export enum DealStatus {
  EXPIRED = 'Expired',
  DRAFT = 'Draft',
  IN_REVIEW = 'InReview',
  INFINYTE_APPROVED = 'InfinyteApproved',
  ACTIVE = 'Active',
  CLOSED = 'Closed'
}

export enum DealTypes {
  PUBLIC = 'Public',
  PRIVATE = 'Private',
  SYNDICATE = 'Syndicate'
  // SYNDICATE_ALL = 'Syndicate-all'
}

export enum EarlyAccessTypes {
  PUBLIC = 'Public',
  PRIVATE = 'Private'
}

export enum OpportunityTypes {
  WAITLIST = 'WaitList',
  WISHLIST = 'WishList'
}

export enum DealCategoryType {
  PRIMARY = 'Primary',
  SECONDARY = 'Secondary'
}

export enum DealAccessTypes {
  INVITE_ONLY = 'InviteOnly',
  APPLY_FOR_ACCESS = 'ApplyForAccess',
  OPEN = 'Open'
}

export enum DealInstrumentTypes {
  CCPS = 'CCPS',
  CCD = 'CCD',
  EQUITY = 'Equity'
}

export enum DealRouteTypes {
  AIF = 'AIF',
  DIRECT = 'Direct',
  SAFE = 'SAFE'
}

export enum ProRataRightsTypes {
  INCLUDED = 'Included',
  NOT_INCLUDED = 'Not Included'
}

export enum ValuationTypes {
  PRICED = 'Priced',
  CONVERTIBLE = 'Convertible'
}

export enum DealModeType {
  PRODUCTION = 'Production',
  TEST = 'Test'
}

export enum DealInviteeStatus {
  INVITED = 'Invited',
  ONBOARDED = 'OnBoarded',
  ONBOARDING_IN_PROGRESS = 'OnBoardingInProgress',
  NOT_INVITED = 'NotInvited'
}

export enum SyndicateInviteeStatus {
  INVITED = 'Invited',
  JOINED = 'Joined'
}

export enum SyndicateStatus {
  DRAFT = 'Draft',
  IN_REVIEW = 'InReview',
  ACTIVE = 'Active'
}

export enum SyndicateUserRoles {
  LEAD = 'syndicate-lead',
  LP = 'syndicate-lp'
}

export enum ActionTypes {
  CHANGE_STATE = 'change_state',
  DUPLICATE = 'duplicate',
  EDIT = 'edit',
  DELETE = 'delete',
  INVITE = 'invite',
  CLOSE = 'close'
}

export enum ProgressBarTextMode {
  ALLOCATION_STATUS = 'AllocationStatus',
  COMMITMENT_STATUS = 'CommitmentStatus',
  RAISED_STATUS = 'RaisedStatus'
}

export const PaginationValues = [25, 50, 100];

export const LIST_ACTIONS = [
  {
    id: 'change_state',
    label: translate('actionOptions.changeState')
  },
  // {
  //   id: 'duplicate',
  //   label: translate('actionOptions.duplicate')
  // },
  {
    id: 'edit',
    label: translate('actionOptions.edit')
  },
  {
    id: 'invite',
    label: translate('actionOptions.invite')
  },
  {
    id: 'delete',
    label: translate('actionOptions.delete')
  }
];

export enum OnboardingStatus {
  INVITED = 'Invited',
  NOT_INVITED = 'NotInvited',
  PHONE_VERIFIED = 'PhoneVerified',
  EMAIL_VERIFIED = 'EmailVerified',
  FULL_NAME_SET = 'FullNameSet',
  ONBOARDED = 'Onboarded',
  ACCREDITATION_SET = 'AccreditationSet',
  IDENTITY_SET = 'IdentitySet'
}

export enum UserOnboardingStatus {
  INVITED = 'invited',
  NOT_INVITED = 'not_invited',
  PHONE_VERIFIED = 'phone_verified',
  EMAIL_VERIFIED = 'email_verified',
  FULL_NAME_SET = 'full_name_set',
  ONBOARDED = 'onboarded',
  ACCREDITATION_SET = 'accreditation_set',
  IDENTITY_SET = 'identity_set',
  SECONDARY_EMAIL_VERIFIED = 'secondary_email_verified',
  KYC_IN_PROGRESS = 'kyc_in_progress',
  KYC_DONE = 'kyc_done',
  WAITLISTED = 'wait_listed'
}

export enum AifOnboardingStatus {
  ACCREDITATED_INVESTOR = 'Accredited investor',
  PPM_ACCEPTED = 'PPM accepted',
  UCA_USER_SIGNED = 'UCA user signed',
  UCA_TRUSTEE_SIGNED = 'UCA trustee signed',
  IOA_DIRECTOR_SIGNED = 'IOA Director signed',
  IOA_IM_SIGNED = 'IOA IM signed',
  IOA_USER_SIGNED = 'IOA user signed',
  UCA_IM_SIGNED = 'UCA IM signed',
  UCA_FULLY_SIGNED = 'UCA Fully Signed',
  AIF_ONBOARDED = 'AIF Onboarded',
  IOA_FULLY_SIGNED = 'IOA Fully Signed'
}

export enum UserActionTypes {
  VIEW = 'view',
  DELETE = 'delete',
  CAPITAL_CONTRIBUTION = 'capital_contribution',
  ENABLE_ROLLING_FUND = 'enable_rolling_fund',
  DISABLE_ROLLING_FUND = 'disable_rolling_fund'
}

export const USER_LIST_ACTIONS = [
  {
    id: 'view',
    label: translate('users.actionLabels.view')
  },
  {
    id: 'capital_contribution',
    label: translate('users.actionLabels.capitalContribution')
  },
  {
    id: 'enable_rolling_fund',
    label: translate('users.actionLabels.enableRollingFund')
  },
  {
    id: 'disable_rolling_fund',
    label: translate('users.actionLabels.disableRollingFund')
  }
  // {
  //   id: 'delete',
  //   label: translate('users.actionLabels.delete')
  // }
];

export enum ResidentType {
  INDIAN_RESIDENT = 'ResidentIndian',
  NRI = 'NonResidentIndian'
}

export enum AddressSource {
  AADHAAR = 'Aadhaar',
  PASSPORT = 'Passport',
  // PAN = 'Pan',
  VOTERSID = 'VotersId'
}

export enum Gender {
  MALE = 'Male',
  FEMALE = 'Female',
  OTHER = 'Other'
}

export enum AccountType {
  NRO = 'NRE',
  NRE = 'NRO',
  FOREIGN = 'Foreign',
  INDIAN = 'Indian'
}

export enum BooleanValues {
  TRUE = 'true',
  FALSE = 'false'
}

export enum AccreditationStatus {
  MORE_THAN_TWO_CR = 'more_than_two_crore',
  MORE_THAN_EIGHT_CR = 'more_than_eight_crore',
  NOT_ACCREDITED = 'not_accredited'
}

export enum ResidentalStatus {
  RESIDENT = 'resident',
  NON_RESIDENT = 'non_resident'
}

export enum DocumentActionTypes {
  VIEW = 'view',
  REGENERATE = 'regenerate',
  DOWNLOAD = 'download',
  REPLACE = 'replace',
  COPY_AIF_ESIGN_URL = 'copy_aif_esign_url',
  COPY_DIRECTOR_ESIGN_URL = 'copy_director_esign_url',
  COPY_INVESTMENT_MANAGER_ESIGN_URL = 'copy_investment_manager_esign_url',
  COPY_TRUSTEE_ESIGN_URL = 'copy_trustee_esign_url',
  COPY_USER_ESIGN_URL = 'copy_user_esign_url'
}

export const DOCUMENT_LIST_ACTIONS = [
  { id: 'view', label: translate('users.actionLabels.view') },

  {
    id: 'regenerate',
    label: translate('users.documents.regenerate')
  },

  {
    id: 'replace',
    label: translate('users.documents.replace')
  },
  {
    id: 'download',
    label: translate('users.documents.downloadAuditTrial')
  },
  {
    id: 'copy_aif_esign_url',
    label: translate('users.documents.copyAifEsign')
  },
  {
    id: 'copy_director_esign_url',
    label: translate('users.documents.copyDirectorEsign')
  },
  {
    id: 'copy_investment_manager_esign_url',
    label: translate('users.documents.investmentManagerEsign')
  },
  {
    id: 'copy_trustee_esign_url',
    label: translate('users.documents.trusteeEsign')
  },
  {
    id: 'copy_user_esign_url',
    label: translate('users.documents.userEsignUrl')
  }
];

export enum DocumentStatus {
  PROCESSING = 'Processing',
  GENERATED = 'DocGenerated',
  APPROVED = 'approved',
  USER_SIGNED = 'UserSigned',
  TRUSTEE_SIGNED = 'TrusteeSigned',
  DIRECTOR_SIGNED = 'DirectorSigned',
  MAIL_SENT = 'MailSent',
  SIGNING_URL_GENERATED = 'SigningUrlGenerated',
  SIGNED = 'Signed',
  DOC_NOT_GENERATED = 'DocNotGenerated',
  USER_ACCEPTED = 'UserAccepted'
}

export enum KRA_STATUS {
  NRI = 'NonResidentIndian',
  INDIAN_RESIDENT = 'ResidentIndian'
  // NOT_AVAILABLE = null
}

export enum DocumentCategory {
  USER = 'user',
  DEAL = 'deal'
}

export enum ConsentType {
  ESIGN = 'esign',
  USER_ACCEPT = 'user_accept',
  CERTIFICATE = 'certificate'
}

export enum UserDocuments {
  ppm = 'PPM',
  uca = 'UCA',
  ioa = 'IOA',
  ucaAddendum = 'UCAAddendum',
  unitCertificate = 'UnitCertificate'
}

export enum DealDocuments {
  icf = 'ICF',
  drawdown = 'DrawDown',
  sharecertificate = 'ShareCertificate'
}

export enum DrawDownStateType {
  mailnotsend = 'MailNotSent',
  mailsend = 'MailSent'
}

export enum KycProofReviewStatus {
  APPROVED = 'Approved',
  IN_REVIEW = 'InReview',
  REJECTED = 'Rejected'
}

export enum DealInviteActionTypes {
  COMMITED_ADD_PRECOMMIT = 'CommitedAddPrecommit',
  ADD_PRECOMMIT = 'AddPrecommit',
  UPDATE_PRECOMMIT = 'UpdatePrecommit',
  REMOVE_INVITE = 'RemoveUserInvite'
}
export const EXCEL_FORMAT = {
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
    '.xlsx',
    '.xls'
  ]
};

export enum OperationStatus {
  DUPLICATE_ENTRY = 'DUPLICATE_ENTRY',
  FAILED = 'FAILED',
  NOT_PROCESSED = 'NOT_PROCESSED',
  SUCCESS = 'SUCCESS',
  VALIDATION_ERROR = 'VALIDATION_ERROR'
}
export const VERIFY_USER_LIST = [
  {
    id: 'view',
    label: translate('kycVerification.action.view')
  }
  // {
  //   id: 'contact',
  //   label: translate('kycVerification.action.contact')
  // }
];

export enum ApprovalStatusValues {
  VERIFIED = 'verified',
  UNVERIFIED = 'unverified'
}

export enum ApprovalStatus {
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  INREVIEW = 'InReview'
}

export enum ScreeningTabHeadValues {
  SCREENING = 'screening',
  PASSED = 'passed',
  WAITLISTED = 'waitListed',
  REJECTED = 'rejected'
}

export const TEXT_FIELD_MAX_LENGTH = 21;
export const SHORT_FIELD_MAX_LENGTH = 250;
export const DEAL_TERMS_MAX_LENGTH = 10;
export const DEAL_TERMS_ROUND_NAME_MAX_LENGTH = 20;
export const VERY_SHORT_FIELD_MAX_LENGTH = 140;
export const LONG_FIELD_MAX_LENGTH = 500;
export const LARGE_FIELD_MAX_LENGTH = 1000;
export const AMOUNT_FIELD_MAX_LENGTH = 13;
export const CURRENT_VALUATION_MAX_LENGTH = 30;
export const CIN_FIELD_MAX_LENGTH = 26;
export const REGISTRATION_NUMBER_FIELD_MAX_LENGTH = 21;
export const HIGHLIGHTS_SMALL_MAX_LENGTH = 10;
export const THIRTY_FIVE_CHAR_MAX_LENGTH = 35;
export const DEAL_HIGHLIGHTS_TITLE_LENGTH = 40;
export const DEAL_CONTENT_TITLE_LENGTH = 25;
export const DEAL_CONTENT_DESCRIPTION_LENGTH = 40;
export const DEAL_METRICS_LENGTH = 40;
export const TRANSACTION_SHORT_DESCRIPTION_LENGTH = 80;
export const CONVERTIBLE_TERMS_MAX_LENGTH = 30;
export const OFFLINE_INVESTMENT_EMAIL = 100;
export const OFFLINE_INVESTMENT_NAME = 250;
export const VALUATION_TYPE_DEAL_TERM = 11;

export const ALLOCATION_MAX_AMOUNT = 1000000000000;
export const MINIMUM_INVESTMENT_MAX_AMOUNT = 1000000000000;
export const MAXIMUM_INVESTMENT_MAX_AMOUNT = 1000000000000;
export const FIXED_CHARGE_MAX_AMOUNT = 1000000000000;
export const CURRENT_VALUATION_MAX_AMOUNT = 1000000000;
export const MAX_AMOUNT = 1000000000000;
export const FUZZY_MATCH_SCORE_LIMIT = 60;

export const CAPITAL_CONTRIBUTION_THRESHOLD = 2500000;

export enum ContactUserKycApprovalStatus {
  APPROVED = 'KycApproved',
  ACTION_REQUIRED = 'KycActionRequired'
}

export enum VerifyUserActionTypes {
  VIEW = 'view',
  CONTACT = 'contact'
}

export enum AccessCodeStatus {
  enable = 'Enable',
  disable = 'Disable'
}

export const accessCodeDuplicateError = 'access_code is duplicate';
export const accessCodeExpiredError = 'access_code is expired';

export const alphaNumericRegex = /^[a-zA-Z0-9]+$/;

export enum AnalyticsListAction {
  RECORD = 'record',
  NOTIFY = 'notify',
  VIEW = 'view',
  GENERATE = 'generate',
  VIEW_INVESTMENT_DETAILS = 'view_investment-details',
  PRE_COMMIT = 'preCommitAmount',
  DROPPED_OFF = 'dropOff',
  BACK_ON = 'backOn',
  REMOVE_INVITE = 'removeUser'
}

export const datePickerCustomStyles = {
  height: '52px',
  width: '100%',
  '& input': {
    height: '19px'
  },
  '& label.Mui-focused': {
    color: colors.primaryBlue
  },
  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: '#000000ab'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: colors.gray88,
      borderRadius: '5px',
      borderWidth: '1px'
    },
    '&.Mui-focused fieldset': {
      borderColor: colors.primaryBlue,
      borderRadius: '5px',
      borderWidth: '1px'
    }
  }
};

export enum UserStatus {
  YET_TO_SIGN_UP = 'yet_to_sign_up',
  SIGNED_UP = 'signed_up',
  KYC_COMPLETED = 'kyc_completed',
  AIF_ONBOARDED = 'aif_onboarded'
}

export enum InvestmentStatus {
  NO_ENGAGEMENT = 'No Engagement',
  COMMITED = 'Committed',
  ICF_SIGNED = 'ICF Signed',
  PAYMENT_COMPLETED = 'Payment Completed',
  DROPPED_OFF = 'Dropped Off'
}

export enum InvestmentStatusTable {
  DROPPED_OFF = 'Dropped Off',
  INVESTMENT_COMPLETED = 'Investment Completed',
  PARTIAL_INVESTMENT = 'Partial Investment',
  DRAWDOWN_SENT = 'Drawdown Sent',
  ICF_SIGNED = 'ICF Signed',
  COMMITTED = 'Committed',
  NO_ENGAGEMENT = 'No Engagement'
}

export enum DetailedUserStatus {
  INVITED = 'invited',
  EMAIL_VERIFIED = 'email_verified',
  PHONE_VERIFIED = 'phone_verified',
  FULL_NAME_SET = 'full_name_set',
  IDENTITY_SET = 'identity_set',
  SIGNED_UP = 'signed_up',
  EDUCATION_ADDED = 'education_added',
  NOT_INVITED = 'not_invited',
  ACCREDITATION_SET = 'accreditation_set',
  ONBOARDED = 'onboarded',
  ADDRESS_PROOF_VERIFIED = 'address_proof_verified',
  BANK_VERIFIED = 'bank_verified',
  PAN_VERIFIED = 'pan_verified',
  NOMINEE_ADDED = 'nominee_added',
  PPM_ACCEPTED = 'ppm_accepted',
  IOA_SIGNED = 'ioa_signed',
  UCS_SIGNED = 'ucs_signed',
  UCA_ADDENDUM_SIGNED = 'uca_addendum_signed',
  COMPLETED = 'completed'
}

export enum InvestorRoles {
  INVESTOR = 'investor',
  COMPANY_FOUNDER = 'company_founder',
  SYNDICATE_LEAD = 'syndicate_lead',
  SYNDICATE_LP = 'syndicate_lp',
  INVALID_ROLE = 'invalid_role'
}

export enum CompanyStatus {
  DRAFT = 'Draft',
  ACTIVE = 'Active'
}

export enum OpportunityStatus {
  EXPIRED = 'Expired',
  DRAFT = 'Draft',
  ACTIVE = 'Active',
  CLOSED = 'Closed'
  //INFINYTE_REVIEW = 'InReview'
}

export const AnalyticsCardRadiusValues = {
  1: [{ innerRadius: 40, outerRadius: 70 }],
  2: [
    { innerRadius: 60, outerRadius: 70 },
    { innerRadius: 45, outerRadius: 55 }
  ]
};

export enum Tabs {
  analytics = 'Analytics',
  tableView = 'Table View'
}

export enum CompanyProductType {
  APP = 'App',
  WEB = 'Web'
}

export const AifId = {
  Bowden: process.env.REACT_APP_BOWDEN_AIF_ID,
  IC: process.env.REACT_APP_IC_AIF_ID
};

export enum OpportunitySoldOutEvent {
  SoldOut = 'soldOut',
  Live = 'live',
  SetLimit = 'setLimit'
}

export enum ProgressBarShowing {
  NotShow = 'notShow',
  Show = 'show',
  ShowText = 'showText'
}

export enum InvestorsIcons {
  Hide = 'hide',
  Show = 'show',
  ShowCount = 'showCount'
}

export enum ShowCardLabel {
  Hide = 'hide',
  Show = 'show'
}

export enum ShowDealType {
  isMoneyBall = 'isMoneyBall',
  isFaaS = 'isFaaS',
  normal = 'normal'
}

export enum AdditionalInfoChange {
  Hide = 'hide',
  ShowProgressBar = 'showProgressBar',
  ShowProgressBarAllocation = 'showProgressBarAllocation',
  ShowProgressBarCommitment = 'showProgressBarCommitment',
  ShowIcons = 'showIcons',
  ShowIconsCount = 'showIconsCount',
  ShowSubscribedCount = 'showSubscribedCount'
}
